.App {
  background: #fff;
  height: 100vh;
  min-width: 90vw;
}

.todo-list {
  background: #e8e8e8;
  border-radius: 4px;
  padding: 15px;
  margin: auto;
  width: 80%;
  min-height: 80%;
}

.todo {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 6px;
  padding: 3px 10px;
}

.input {
  width: 100%;
  height: 25px;
  margin: 0px;
  padding: 0px;
}

h1 {
  text-align: center;
  margin: 6px;
}